<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="font-weight-bolder">
          {{ $t("message.chart.opp") }}
        </b-card-title>
        <b-card-sub-title class="mt-25">
          {{ $t("message.chart.oppDesc") }}
        </b-card-sub-title>
      </div>

      <div class="d-flex align-items-center">
        <feather-icon icon="CalendarIcon" size="16" />
        <v-select
          v-model="period"
          :reduce="(val) => val.value"
          :options="periodOptions"
          class="ml-1"
          style="width: 250px"
          :clearable="false"
          @input="loadPeriod(period)"
        />
      </div>
    </b-card-header>
    <b-card-body>
      <vue-apex-charts
        type="pie"
        :height="chart.height"
        :options="chartOptions"
        :series="series"
      />
      <div class="float-md-right d-none d-md-block">
        <h4 class="d-flex mt-2">
          {{ "Total: " + chartData[3] }}
        </h4>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import moment from "moment";
import store from "@/store";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    flatPickr,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    vSelect,
  },

  data() {
    return {
      chart: {},
      series: [],
      chartOptions: {},
      paramData: store.state.user.paramData,
      chartData: [0, 0, 0, 0],

      chartTimestamp: "",

      userData: store.state.user.userData,
      period: "all",
      periodOptions: [],
    };
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.loadChartData();
    },
  },

  async created() {
    await this.loadChartData();
  },

  methods: {
    loadPeriod(period) {
      if (period === "all") {
        this.period = "all";
      } else {
        this.period = period;
      }

      this.loadChartData();
    },

    async loadChartData() {
      var vm = this;

      await axios
        .get(`${URL_API}chart/opportunity/${this.period}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          var string = response.data.chartInfo;
          var clear = string.slice(1, -1);
          this.chartData = clear.split(",");

          if (string != null) {
            this.chartTimestamp = response.data.timestamp;

            if (this.period === "all") {
              var yearsString = clear.substring(
                clear.indexOf("[") + 1,
                clear.indexOf("]")
              );
              var years = yearsString.split(",").map((item) => item.trim());
              this.getAllPeriodsAvailable(years);
            }
          }
        });

      this.chart = {
        height: "500",
      };

      this.series = [
        parseInt(this.chartData[0]),
        parseInt(this.chartData[1]),
        parseInt(this.chartData[2]),
      ];

      this.chartOptions = {
        labels: [
          this.getLabel("won"),
          this.getLabel("lost"),
          this.getLabel("pending"),
        ],
        dataLabels: {
          enabled: true,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                positon: "bottom",
                show: false,
              },
            },
          },
        ],

        colors: ["#00e396", "#d95555", "#feb019"],

        legend: {
          show: true,
          position: "top",
        },

        title: {
          text: vm.localeDate(this.chartTimestamp),
          align: "right",
          margin: 10,
          offsetX: 0,
          offsetY: 25,
          floating: false,
          style: {
            fontSize: "12px",
            fontWeight: "normal",
          },
        },
      };
    },

    getAutoHeight(value) {
      if (value === 0) {
        return 600;
      } else if (value === 1) {
        return 2 * 100;
      } else {
        return value * 120;
      }
    },

    getLabel(colName) {
      return this.$i18n.t("message.chart." + colName);
    },

    localeDate(value) {
      var newFormat = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "MM-DD-YYYY"
      );
      var newHour = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "HH:mm:ss"
      );

      if (newFormat != "Invalid date" && newFormat != null) {
        return (
          this.$i18n.d(
            new Date(newFormat.split("-")),
            "short",
            this.$i18n.locale
          ) +
          " - " +
          newHour
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    getColLabel(colName) {
      return this.$i18n.t("message.chart." + colName);
    },

    currencyValue(value) {
      return this.$i18n.n(value, "currency");
    },

    getAllPeriodsAvailable(years) {
      var vm = this;
      this.periodOptions = [];

      this.periodOptions.push({
        label: vm.getColLabel("all"),
        value: "all",
      });

      for (let i = 0; i < years.length; i++) {
        this.periodOptions.push({
          label: years[i],
          value: years[i],
        });
      }
    },
  },
};
</script>

<style scoped>
.date-picker {
  min-width: 10rem;
}
</style>
